import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Backdrop, CircularProgress, Divider, Grid} from "@mui/material";
import AirQualityGauge from "../../components/AirQualityGauge/AirQualityGauge";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from "moment";
import 'moment/locale/de';
import styles from './styles.module.css';
import {CurrentAirQualityData} from "../../types/sensordata.types";
import BoschAPIService from "../../api/bosch-api.client";
import {getMajorPollutantValue,} from "../../helper/airQualityHelper";
import AqiDetails from "../../components/AqiDetails/AqiDetails";
import WidgetFooter from "../../components/WidgetFooter/WidgetFooter";

export default function AirQualityContainer() {

    const [currentAQIData, setCurrentAQIData] = useState<CurrentAirQualityData>();
    const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);
    const [stationId]: any = React.useState(process.env.REACT_APP_STATION_ID);

    const retrieveCurrentAQIData = useCallback(() => {
        if (stationId > 0) {
            BoschAPIService.getCurrentAQIData(stationId)
                .then(function (response: any) {
                    setCurrentAQIData(response.data[0]);
                    setShowLoadingSpinner(false);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, [stationId]);

    /*
    const retrieveBundesumweltamtAQIData = useCallback(() => {
        if (stationId > 0) {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow',
                accepts: 'application/json'
            };

            // @ts-ignore
            fetch("/api/air_data/v2/airquality/json?date_from=2022-12-13&time_from=11&date_to=2022-12-13&time_to=13&station=233", requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result).data;
                    console.log(Object.values(res[233])[0])

                })
                .catch(error => console.log('error', error));

        }
    }, [stationId]);
    */

    // Initialer Daten Load
    useEffect(() => {
        setShowLoadingSpinner(true);
        retrieveCurrentAQIData();
        // retrieveBundesumweltamtAQIData();
    }, [retrieveCurrentAQIData]);

    // neue Daten alle 20 Sekunden
    useEffect(() => {
        const interval = setInterval(() => {

            setShowLoadingSpinner(true);
            retrieveCurrentAQIData();

        }, 20000);
        return () => clearInterval(interval);
    }, [retrieveCurrentAQIData]);


    moment().locale('DE');
    const currentDate = moment().format('Do MMMM YYYY');

    const handleCloseLoadingSpinner = () => {
        setShowLoadingSpinner(false);
    };

    const aqiDetails = (process.env.REACT_APP_AQI_DETAILS_VISIBLE && JSON.parse(process.env.REACT_APP_AQI_DETAILS_VISIBLE)) ?
        <div className={styles.bottomMargin}>
            <AqiDetails
                setShowLoadingSpinner={setShowLoadingSpinner}
                currentAQIData={currentAQIData}/>
        </div> : null;

    return (
        <React.Fragment>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={showLoadingSpinner}
                onClick={handleCloseLoadingSpinner}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Box sx={{flexGrow: 1}} className={styles.bottomMargin}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <h2>LUFTQUALITÄT</h2>
                        <Divider/>
                    </Grid>

                    <Grid item xs={3} sm={6} className={styles.textAlignRight}>
                        <LocationOnIcon sx={{fontSize: 40}}/>
                    </Grid>

                    <Grid item xs={9} sm={6} className={styles.textAlignLeft}>
                        <span className={styles.meterLocation}>{process.env.REACT_APP_TOWN_LABEL}</span> <br/>
                        {currentDate}
                    </Grid>

                    <Grid item xs={12}>
                    <span className={styles.gaugeHeader}>
                        AIR QUALITY INDEX
                    </span>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <AirQualityGauge
                                indexLevel={getMajorPollutantValue(currentAQIData).index_level}
                                currentValue={getMajorPollutantValue(currentAQIData).index_value}
                                diagramTitle={''}
                                diagramSubTitle={''}
                                airQualityIndex={true}
                                majorPollutant={currentAQIData?.payload.MajorPollutant ?? ''}
                                utcTimestamp={currentAQIData?.payload.UTC ?? ''}
                            />
                        </Box>
                        <br/>
                        <Divider/>
                    </Grid>
                </Grid>
            </Box>

            {aqiDetails}

            <WidgetFooter/>

        </React.Fragment>
    );
}