import React from 'react';
// @ts-ignore
import GaugeChart from 'react-gauge-chart'
import PropTypes from "prop-types";

import styles from './styles.module.css';
import {determinePollutionLabel, getPollutantLabel, QUALITY_CLASSIFICATION} from "../../helper/airQualityHelper";
import moment from "moment";

type AirQualityGaugeProps = {
    currentValue: number,
    diagramTitle: string,
    diagramSubTitle: string,
    airQualityIndex: boolean,
    indexLevel: string,
    majorPollutant: string,
    utcTimestamp: string
}

const AirQualityGauge = ({
                             currentValue,
                             diagramTitle,
                             diagramSubTitle,
                             airQualityIndex,
                             indexLevel,
                             majorPollutant,
                             utcTimestamp
                         }: AirQualityGaugeProps) => {

    const gaugeId = "gauge-chart-" + {diagramTitle};
    const chartStyle = airQualityIndex ? {width: 300} : {width: 200}

    const gaugeHeader = airQualityIndex ?
        null :
        <span>
            <h4>{diagramTitle} <span className={styles.subTitle}>{diagramSubTitle}</span></h4>
            </span>;

    const arcsLength = 1 / 6;


    const determinePollutionValue = (indexLevel: string) => {
        switch (indexLevel) {
            case QUALITY_CLASSIFICATION.EXTREMELY_POOR:
                return (5 * arcsLength) + (arcsLength / 2);
            case QUALITY_CLASSIFICATION.VERY_POOR:
                return (4 * arcsLength) + (arcsLength / 2);
            case QUALITY_CLASSIFICATION.POOR:
                return (3 * arcsLength) + (arcsLength / 2);
            case QUALITY_CLASSIFICATION.MODERATE:
                return (2 * arcsLength) + (arcsLength / 2);
            case QUALITY_CLASSIFICATION.FAIR:
                return (arcsLength) + (arcsLength / 2);
            case QUALITY_CLASSIFICATION.GOOD:
                return (arcsLength / 2);
        }
    }

    const valueLabel = !airQualityIndex ?
        <div><span>{Math.round(currentValue * 100) / 100}</span><span> µg/m³</span></div> :
        <span>Hauptschadstoff: {getPollutantLabel(majorPollutant)}</span>;

    const aqiMeasureDate = utcTimestamp ? 'Berechnet am ' + moment(utcTimestamp).format('Do MMMM YYYY · HH:mm') + ' Uhr' : '';
    const aqiSubDescription = <h3> {determinePollutionLabel(indexLevel)}</h3>;

    return (
        <div className={styles.gaugeDiagramm}>
            {gaugeHeader}
            <GaugeChart id={gaugeId}
                        animate={false}
                        nrOfLevels={420}
                        arcsLength={[arcsLength, arcsLength, arcsLength, arcsLength, arcsLength, arcsLength]}
                        colors={['#67f948', '#9deb56', '#efff42', '#fde143', '#ff4242', '#960032']}
                        hideText={true}
                        percent={determinePollutionValue(indexLevel)}
                        arcPadding={0.02}
                        needleColor={'#000000'}
                        needleBaseColor={'#000000'}
                        cornerRadius={2}
                        style={chartStyle}/>
            {aqiSubDescription}
            {valueLabel}
            <br/>
            <span>{aqiMeasureDate}</span>
        </div>
    );
}

AirQualityGauge.propTypes = {
    currentValue: PropTypes.number,
    diagramTitle: PropTypes.string.isRequired,
    indexLevel: PropTypes.string,
    diagramSubTitle: PropTypes.string.isRequired,
    airQualityIndex: PropTypes.bool.isRequired,
    majorPollutant: PropTypes.string,
    utcTimestamp: PropTypes.string,
};

export default AirQualityGauge;