import {PredictionDataEntries} from "../types/predictiondata.types";
import moment from "moment";
import {FraunhoferAxiosClient} from "./http-client-base";

const getPhenomenOnTimeFilter = () => {

    const date = moment().add(1, 'd').toDate();
    const isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

    return '?$filter=phenomenonTime%20gt%20' + isoDateTime + '&$top=25&$orderBy=resultTime%20asc,%20phenomenonTime%20asc'
}

const getNO2PredictionSensorData = () => {
    const dataStreamNr = process.env.REACT_APP_NO2_PREDICTION_DATASTREAM;
    return FraunhoferAxiosClient.get<PredictionDataEntries>('/Datastreams(' + dataStreamNr + ')/Observations' + getPhenomenOnTimeFilter());
}

const getPM25PredictionSensorData = () => {
    const dataStreamNr = process.env.REACT_APP_PM25_PREDICTION_DATASTREAM;
    return FraunhoferAxiosClient.get<PredictionDataEntries>('/Datastreams(' + dataStreamNr + ')/Observations' + getPhenomenOnTimeFilter());
}

const getPM10PredictionSensorData = () => {
    const dataStreamNr = process.env.REACT_APP_PM10_PREDICTION_DATASTREAM;
    return FraunhoferAxiosClient.get<PredictionDataEntries>('/Datastreams(' + dataStreamNr + ')/Observations' + getPhenomenOnTimeFilter());
}

const FraunhoferAPIService = {
    getNO2PredictionSensorData, getPM25PredictionSensorData, getPM10PredictionSensorData
};

export default FraunhoferAPIService;
