import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationDE from './locales/de/translation.json';

export const defaultNS = "translation";

export const resources = {
    de: {
        translation: translationDE
    }
} as const;

i18n
    .use(initReactI18next)
    .init({
        resources,
        defaultNS,
        keySeparator: '.',
        lng: 'de',
        fallbackLng: 'de',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
