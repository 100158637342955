import {CurrentAirQualityData, MajorPollutantValue} from "../types/sensordata.types";

export const getNO2Classification = (value: number | undefined): string => {
    if (!value) {
        return QUALITY_CLASSIFICATION.GOOD;
    }

    if (value >= 0 && value <= 25) {
        return QUALITY_CLASSIFICATION.GOOD;
    } else if (value > 25 && value <= 50) {
        return QUALITY_CLASSIFICATION.FAIR;
    } else if (value > 50 && value <= 100) {
        return QUALITY_CLASSIFICATION.MODERATE;
    } else if (value > 100 && value <= 200) {
        return QUALITY_CLASSIFICATION.POOR;
    } else if (value > 200 && value <= 500) {
        return QUALITY_CLASSIFICATION.VERY_POOR;
    } else if (value > 500) {
        return QUALITY_CLASSIFICATION.EXTREMELY_POOR;
    }

    return QUALITY_CLASSIFICATION.GOOD;
}

export const getO3Classification = (value: number | undefined): string => {
    if (!value) {
        return QUALITY_CLASSIFICATION.GOOD;
    }

    if (value >= 0 && value <= 33) {
        return QUALITY_CLASSIFICATION.GOOD;
    } else if (value > 33 && value <= 65) {
        return QUALITY_CLASSIFICATION.FAIR;
    } else if (value > 65 && value <= 120) {
        return QUALITY_CLASSIFICATION.MODERATE;
    } else if (value > 120 && value <= 180) {
        return QUALITY_CLASSIFICATION.POOR;
    } else if (value > 180 && value <= 240) {
        return QUALITY_CLASSIFICATION.VERY_POOR;
    } else if (value > 240) {
        return QUALITY_CLASSIFICATION.EXTREMELY_POOR;
    }

    return QUALITY_CLASSIFICATION.GOOD;
}

export const getPM10Classification = (value: number | undefined): string => {
    if (!value) {
        return QUALITY_CLASSIFICATION.GOOD;
    }

    if (value >= 0 && value <= 20) {
        return QUALITY_CLASSIFICATION.GOOD;
    } else if (value > 20.1 && value <= 50) {
        return QUALITY_CLASSIFICATION.FAIR;
    } else if (value > 50.1 && value <= 80) {
        return QUALITY_CLASSIFICATION.MODERATE;
    } else if (value > 80.1 && value <= 110) {
        return QUALITY_CLASSIFICATION.POOR;
    } else if (value > 110.1 && value <= 150) {
        return QUALITY_CLASSIFICATION.VERY_POOR;
    } else if (value > 150) {
        return QUALITY_CLASSIFICATION.EXTREMELY_POOR;
    }

    return QUALITY_CLASSIFICATION.GOOD;
}

export const getPM25Classification = (value: number | undefined): string => {
    if (!value) {
        return QUALITY_CLASSIFICATION.GOOD;
    }

    if (value >= 0 && value <= 13) {
        return QUALITY_CLASSIFICATION.GOOD;
    } else if (value > 13.1 && value <= 35) {
        return QUALITY_CLASSIFICATION.FAIR;
    } else if (value > 35.1 && value <= 55) {
        return QUALITY_CLASSIFICATION.MODERATE;
    } else if (value > 55.1 && value <= 75) {
        return QUALITY_CLASSIFICATION.POOR;
    } else if (value > 75.1 && value <= 110) {
        return QUALITY_CLASSIFICATION.VERY_POOR;
    } else if (value > 110) {
        return QUALITY_CLASSIFICATION.EXTREMELY_POOR;
    }

    return QUALITY_CLASSIFICATION.GOOD;
}

export const getClassification = (value: number | undefined, pollutant: string): string => {

    if (pollutant === POLLUTANTS_SHORT.O3) {
        return getO3Classification(value);
    } else if (pollutant === POLLUTANTS_SHORT.NO2) {
        return getNO2Classification(value);
    } else if (pollutant === POLLUTANTS_SHORT.PM10) {
        return getPM10Classification(value);
    } else if (pollutant === POLLUTANTS_SHORT.PM25) {
        return getPM25Classification(value);
    }


    return QUALITY_CLASSIFICATION.GOOD;
}

export const QUALITY_CLASSIFICATION = {
    GOOD: 'GOOD',
    FAIR: 'FAIR',
    MODERATE: 'MODERATE',
    POOR: 'POOR',
    VERY_POOR: 'VERY POOR',
    EXTREMELY_POOR: 'EXTREMELY POOR'
};

export const QUALITY_CLASSIFICATION_FIVE_LEVEL = {
    VERY_GOOD: 'VERY_GOOD',
    GOOD: 'GOOD',
    MODERATE: 'MODERATE',
    POOR: 'POOR',
    VERY_POOR: 'VERY POOR',
};

export const POLLUTANTS = {
    O3: 'Ozon',
    PM25: 'Feinstaub PM 2,5',
    PM10: 'Feinstaub PM 10',
    NO2: 'Stickstoffdioxid',
};

export const POLLUTANTS_SHORT = {
    O3: 'O3',
    PM25: 'PM 2,5',
    PM10: 'PM 10',
    NO2: 'NO2',
};

export const getMajorPollutantValue = (currentAirQuality: CurrentAirQualityData | undefined): MajorPollutantValue => {
    if (!currentAirQuality) {
        return {} as MajorPollutantValue;
    }

    switch (currentAirQuality.payload.MajorPollutant) {
        case 'O3':
            return {
                index_value: currentAirQuality.payload.O3_0_CORR_1hr,
                index_level: currentAirQuality.payload.O3_Index_Level
            }
        case 'SO2':
            return {
                index_value: currentAirQuality.payload.SO2_2_CORR_1hr,
                index_level: currentAirQuality.payload.SO2_Index_Level
            }
        case 'NO2':
            return {
                index_value: currentAirQuality.payload.NO2_1_CORR_1hr,
                index_level: currentAirQuality.payload.NO2_Index_Level
            }
        case 'PM2P5':
            return {
                index_value: currentAirQuality.payload.PS_PM2P5_AVG_24hr,
                index_level: currentAirQuality.payload.PM2P5_Index_Level
            }
        case 'PM10':
            return {
                index_value: currentAirQuality.payload.PS_PM10_AVG_24hr,
                index_level: currentAirQuality.payload.PM10_Index_Level
            }
        default: {
            return {
                index_value: 0,
                index_level: currentAirQuality.payload.EAQI
            }
        }
    }
}

export const getPollutantLabel = (pollutant: string | undefined): string => {

    switch (pollutant) {
        case 'O3':
            return 'Ozon';
        case 'SO2':
            return 'Schwefeldioxid';
        case 'NO2':
            return 'Stickstoffdioxid';
        case 'PM10':
            return 'Feinstaub PM10';
        case 'PM2.5':
            return 'Feinstaub PM2.5';
        default:
            return 'kein Schadstoff';
    }
}

export const getPollutantLabelBar = (pollutant: string | undefined): any => {
    switch (pollutant) {
        case 'O3':
            return <span>O3<br/>(Ozon)</span>;
        case 'NO2':
            return <span>NO2<br/>(Stickstoffdioxid)</span>;
        case 'PM 10':
            return <span>PM 10<br/>(Feinstaub)</span>;
        case 'PM 2,5':
            return <span>PM 2,5<br/>(Feinstaub)</span>;
        default:
            return;
    }
}

export const determinePollutionLabel = (indexLevel: string) => {
    switch (indexLevel) {
        case "GOOD":
            return 'Sehr gute Luftqualität';
        case "FAIR":
            return 'Gute Luftqualität';
        case "MODERATE":
            return 'Befriedigende Luftqualität';
        case "POOR":
            return 'Ausreichende Luftqualität';
        case "VERY POOR":
            return 'Schlechte Luftqualität';
        case "EXTREMELY POOR":
            return 'Sehr schlechte Luftqualität';
    }
}