import React from 'react';
import './App.css';
import AirQualityContainer from "./container/AirQualityContainer";

function App() {
    return (
        <div className="App">
            <header className="App-header" />
            <AirQualityContainer/>
        </div>
    );
}

export default App;
