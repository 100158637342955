import React, {useCallback, useEffect, useState} from 'react';
import styles from './styles.module.css';
import {FormControlLabel, Grid, Switch} from "@mui/material";
import {POLLUTANTS_SHORT} from "../../helper/airQualityHelper";

import icon_current from '../../assets/images/icon_current.png'
import icon_plus1 from '../../assets/images/icon_plus1.png'
import icon_plus2 from '../../assets/images/icon_plus2.png'
import FraunhoferAPIService from "../../api/fraunhofer-api.client";
import {PredictionNextDays} from "../../types/predictiondata.types";
import AirQualityBar from "../AirQualityBar/AirQualityBar";
import moment from "moment";
import {CurrentAirQualityData} from "../../types/sensordata.types";

type Pollutant = {
    setShowLoadingSpinner: (showSpinner: boolean) => void;
    currentAQIData: CurrentAirQualityData | undefined;
}

const AqiDetails = ({
                        setShowLoadingSpinner,
                        currentAQIData
                    }: Pollutant) => {

    const [foreCastNO2, setForeCastNO2] = useState<PredictionNextDays>();
    const [foreCastPM25, setForeCastPM25] = useState<PredictionNextDays>();
    const [foreCastPM10, setForeCastPM10] = useState<PredictionNextDays>();
    const [showDetailsChecked, setShowDetailsChecked] = React.useState(process.env.REACT_APP_AQI_DETAILS_AUTO_OPEN ? JSON.parse(process.env.REACT_APP_AQI_DETAILS_AUTO_OPEN) : false);
    const [stationId]: any = React.useState(process.env.REACT_APP_STATION_ID);
    const foreCastEnabled: boolean = process.env.REACT_APP_AQI_FORECAST_ENABLED ? JSON.parse(process.env.REACT_APP_AQI_FORECAST_ENABLED) : false

    const handleDetailsSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDetailsChecked(event.target.checked);
    };

    const retrievePredictionData = useCallback(() => {
        if (stationId > 0 && foreCastEnabled) {
            FraunhoferAPIService.getNO2PredictionSensorData()
                .then(function (response: any) {
                    setShowLoadingSpinner(false);
                    if (response.data.value.length > 0) {
                        const lastEntry = response.data.value.length - 1;
                        let prediction: PredictionNextDays = {
                            dayAfterTomorrowValue: response.data.value[lastEntry].result,
                            tomorrowValue: response.data.value[0].result,
                            pollutant: POLLUTANTS_SHORT.NO2
                        };

                        setForeCastNO2(prediction);
                    } else {
                        console.log('no prediction no2');
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            FraunhoferAPIService.getPM25PredictionSensorData()
                .then(function (response: any) {
                    setShowLoadingSpinner(false);
                    if (response.data.value.length > 0) {
                        const lastEntry = response.data.value.length - 1;
                        let prediction: PredictionNextDays = {
                            dayAfterTomorrowValue: response.data.value[lastEntry].result,
                            tomorrowValue: response.data.value[0].result,
                            pollutant: POLLUTANTS_SHORT.NO2
                        };

                        setForeCastPM25(prediction);
                    } else {
                        console.log('no prediction pm2.5');
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            FraunhoferAPIService.getPM10PredictionSensorData()
                .then(function (response: any) {
                    setShowLoadingSpinner(false);

                    if (response.data.value.length > 0) {
                        const lastEntry = response.data.value.length - 1;
                        let prediction: PredictionNextDays = {
                            dayAfterTomorrowValue: response.data.value[lastEntry].result,
                            tomorrowValue: response.data.value[0].result,
                            pollutant: POLLUTANTS_SHORT.NO2
                        };

                        setForeCastPM10(prediction);
                    } else {
                        console.log('no prediction pm1ß');
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, [stationId, setShowLoadingSpinner, foreCastEnabled]);

    // Initialer Daten Load
    useEffect(() => {
        setShowLoadingSpinner(true);
        retrievePredictionData();
    }, [retrievePredictionData, setShowLoadingSpinner]);

    // neue Daten alle 20 Sekunden
    useEffect(() => {
        const interval = setInterval(() => {

            setShowLoadingSpinner(true);
            retrievePredictionData();

        }, 20000);
        return () => clearInterval(interval);
    }, [retrievePredictionData, setShowLoadingSpinner]);

    const sensorDataMeasureDate = currentAQIData?.payload.UTC ? 'Werte vom ' + moment(currentAQIData?.payload?.UTC).format('Do MMMM YYYY · HH:mm') + ' Uhr' : '';

    const foreCastLegend = foreCastEnabled ?
        <div className={styles.verticalBottom}>
            <b>Vorhersagen</b><br/>
            <img height={30} src={icon_plus1} alt=""/> für morgen <img height={30} src={icon_plus2} alt=""/> für
            übermorgen
        </div> : null


    const showQualityDetails = (showDetailsChecked) ?
        <React.Fragment>
            <br/>
            <AirQualityBar
                pollutant={POLLUTANTS_SHORT.O3}
                currentValue={currentAQIData?.payload?.O3_0_CORR_1hr ?? 0}
                forecastTomorrow={0}
                forecastDayAfterTomorrow={0}
            />
            <AirQualityBar
                pollutant={POLLUTANTS_SHORT.NO2}
                currentValue={currentAQIData?.payload?.NO2_1_CORR_1hr ?? 0}
                forecastTomorrow={foreCastNO2?.tomorrowValue ?? 0}
                forecastDayAfterTomorrow={foreCastNO2?.dayAfterTomorrowValue ?? 0}
            />
            <AirQualityBar
                pollutant={POLLUTANTS_SHORT.PM25}
                currentValue={currentAQIData?.payload?.PS_PM2P5_AVG_24hr ?? 0}
                forecastTomorrow={foreCastPM25?.tomorrowValue ?? 0}
                forecastDayAfterTomorrow={foreCastPM25?.dayAfterTomorrowValue ?? 0}
            />
            <AirQualityBar
                pollutant={POLLUTANTS_SHORT.PM10}
                currentValue={currentAQIData?.payload?.PS_PM10_AVG_24hr ?? 0}
                forecastTomorrow={foreCastPM10?.tomorrowValue ?? 0}
                forecastDayAfterTomorrow={foreCastPM10?.dayAfterTomorrowValue ?? 0}/>
            <br/>
            <div className={styles.verticalBottom}>
                <img height={30} src={icon_current} alt=""/> {sensorDataMeasureDate}
            </div>
            {foreCastLegend}
            <br/>

        </React.Fragment> :
        null


    const forecastSwitchAndLabel = <Grid item xs={12}>
        <span>
            <FormControlLabel control={
                <Switch
                    checked={showDetailsChecked}
                    onChange={handleDetailsSwitchChange}
                    inputProps={{'aria-label': 'controlled'}}
                />} label={getShowHideLabel()}
            />
        </span>
    </Grid>;

    function getShowHideLabel() {
        if (showDetailsChecked && foreCastEnabled) {
            return 'Einzelwerte & Vorhersagen ausblenden'
        }

        if (showDetailsChecked && !foreCastEnabled) {
            return 'Einzelwerte ausblenden'
        }

        if (!showDetailsChecked && foreCastEnabled) {
            return 'Einzelwerte & Vorhersagen anzeigen'
        }

        if (!showDetailsChecked && !foreCastEnabled) {
            return 'Einzelwerte anzeigen'
        }
    }

    return (
        <React.Fragment>
            {forecastSwitchAndLabel}
            {showQualityDetails}

        </React.Fragment>
    );
}

export default AqiDetails;