import React from "react";
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import i18n from './i18n';
import hbvQR from '../src/assets/images/hbv-qr-code.svg'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <div className="c-intro">
            <p>{i18n.t('app.global-title-teaser')}</p>
            <h1>{i18n.t('app.global-title')}</h1>
        </div>
        <App/>
        <div className="c-outro">
            <p>{i18n.t('app.global-outro')}</p>
            <img className="c-outro__qr-code" src={hbvQR} alt="heilbaederverband"/>
        </div>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
