import React from 'react';
import {Divider, Grid} from "@mui/material";
import styles from "../../container/AirQualityContainer/styles.module.css";
import Box from "@mui/material/Box";


const WidgetFooter = () => {

    return (
        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={3}>
                <Grid item xs={1}/>

                <Grid item xs={10} className={styles.textMargin}>
                    Ihre Gesundheit liegt uns am Herzen. Die Messstation liegt direkt im heilklimatischen Kurort
                    Hinterzarten.
                    Weitere Infos zum Projekt, <a href={'https://www.heilbaeder-bw.de/luftqualitaet'}
                                                  rel={'noreferrer'}
                                                  target={'_blank'}>hier</a> klicken.
                </Grid>
                <Grid item xs={1}/>

                <Grid item xs={6} className={styles.textAlignRight}>
                    <img height={50}
                         src="https://upload.wikimedia.org/wikipedia/de/3/31/Bosch-logotype.svg?auto=compress&cs=tinysrgb"
                         alt="new"/>
                </Grid>
                <Grid item xs={6} className={styles.textAlignLeft}>
                    <img height={30} className={styles.topMargin}
                         src="https://www.heilbaeder-bw.de/_Resources/Static/Packages/Newland.HbvTheme/images/footer-logo.png"
                         alt="new"/>
                    <br/><br/>
                </Grid>

                <Grid item xs={12}>
                    <Divider/>
                    <br/><br/>
                    <div className={styles.poweredBySection}>powered by</div>
                    <img height={45} className={styles.topMargin}
                         src="https://mein.toubiz.de/dist/f89f4969be28761c9a0120cfa90c8023.svg"
                         alt="new"/>
                </Grid>

            </Grid>
        </Box>
    );
}

export default WidgetFooter;