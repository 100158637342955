import {CurrentAirQualityData, CurrentSensorData} from "../types/sensordata.types";
import {BoschAxiosClient} from "./http-client-base";

const getCurrentSensorData = (stationId: number | null) => {
    if (stationId && stationId > 0) {
        return BoschAxiosClient.get<CurrentSensorData>('/currentdata/' + stationId);
    }
}

const getCurrentAQIData = (stationId: number) => {
    return BoschAxiosClient.get<CurrentAirQualityData>('/currentaqi/' + stationId);
}

const BoschAPIService = {
    getCurrentSensorData,
    getCurrentAQIData
};
export default BoschAPIService;


//const currentDataUrl = 'https://bosch-iot-insights.com/r/pyf4020/currentdata/359072065634186';
//const histDataUrl = 'https://bosch-iot-insights.com/r/pyf4020/devicedata/359072065634186/2022-04-14T00:00:00Z/2022-04-14T09:56:00Z';
//const currentAQIDataUrl = 'https://bosch-iot-insights.com/r/pyf4020/currentaqi/359072065634186';
