import React from 'react';
import styles from './styles.module.css';
import {Box, Grid} from "@mui/material";
import {
    getClassification,
    getPollutantLabelBar,
    POLLUTANTS_SHORT,
    QUALITY_CLASSIFICATION
} from "../../helper/airQualityHelper";

import icon_current from '../../assets/images/icon_current.png'
import icon_plus1 from '../../assets/images/icon_plus1.png'
import icon_plus2 from '../../assets/images/icon_plus2.png'

type Pollutant = {
    currentValue: number,
    forecastTomorrow: number,
    forecastDayAfterTomorrow: number,
    pollutant: string
}

const AirQualityBar = ({currentValue, forecastTomorrow, forecastDayAfterTomorrow, pollutant}: Pollutant) => {

    let markerClassification = getClassification(currentValue, pollutant);
    let tomorrowClassification = getClassification(forecastTomorrow, pollutant);
    let dayAfterTomorrowClassification = getClassification(forecastDayAfterTomorrow, pollutant);

    const foreCastEnabled = process.env.REACT_APP_AQI_FORECAST_ENABLED ? JSON.parse(process.env.REACT_APP_AQI_FORECAST_ENABLED) : false;


    const markerSnippet = (QUALITY_CLASSIFICATION: string) => {

        const currentMarker = QUALITY_CLASSIFICATION === markerClassification ?
            <img alt="marker_current" height={30} src={icon_current}/> : null;
        const tomorrowMarker = foreCastEnabled ? (QUALITY_CLASSIFICATION === tomorrowClassification && pollutant !== POLLUTANTS_SHORT.O3 ?
            <img alt="marker_plus1" height={30} src={icon_plus1}/> : null) : null;
        const dayAfterTomorrowMarker = foreCastEnabled ? (QUALITY_CLASSIFICATION === dayAfterTomorrowClassification && pollutant !== POLLUTANTS_SHORT.O3 ?
            <img alt="marker_plus2" height={30} src={icon_plus2}/> : null) : null;

        return (
            <div className={styles.main}>
                <div>
                    {currentMarker} {tomorrowMarker} {dayAfterTomorrowMarker}
                </div>
            </div>
        );
    }

    const pollutantLabel = getPollutantLabelBar(pollutant);

    return (
        <React.Fragment>

            <Box sx={{flexGrow: 1}} className={styles.barText}>
                <Grid container spacing={3}>

                    <Grid item xs={3} style={{textAlign: 'right'}}>
                        <div className={styles.main}>
                            <div className={styles.verticalCenter}> {pollutantLabel}</div>
                        </div>
                    </Grid>

                    <Grid item xs={8}>
                        <Grid container>
                            <Grid item xs={2}>
                                <div className={styles.main}>
                                    <div>
                                        {markerSnippet(QUALITY_CLASSIFICATION.GOOD)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={styles.main}>
                                    <div>
                                        {markerSnippet(QUALITY_CLASSIFICATION.FAIR)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={styles.main}>
                                    <div>
                                        {markerSnippet(QUALITY_CLASSIFICATION.MODERATE)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={styles.main}>
                                    <div>
                                        {markerSnippet(QUALITY_CLASSIFICATION.POOR)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={styles.main}>
                                    <div>
                                        {markerSnippet(QUALITY_CLASSIFICATION.VERY_POOR)}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={styles.main}>
                                    <div>
                                        {markerSnippet(QUALITY_CLASSIFICATION.EXTREMELY_POOR)}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.fancyBorder}>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}

export default AirQualityBar;