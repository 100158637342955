import axios from 'axios';

export const BoschAxiosClient = axios.create({
    baseURL: 'https://bosch-iot-insights.com/r/pyf4020',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic cHlmNDAyMC1yb2Z3Y3gtYXBpOlNtLTBXdkRuS1VkT3VEc2g='
    }
});

export const FraunhoferAxiosClient = axios.create({
    baseURL: 'https://aq-prediction.k8s.ilt-dmz.iosb.fraunhofer.de/FROST-Server/v1.1/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic cmVhZDo5Mm1QRnpFTA=='
    }
});

export const BundesumweltamtAxiosClient = axios.create({
    baseURL: 'https://www.umweltbundesamt.de/api/air_data/v2/airquality/json',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});


// https://www.umweltbundesamt.de/api/air_data/v2/airquality/json?date_from=2022-12-13&time_from=11&date_to=2022-12-13&time_to=13&station=233